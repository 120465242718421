<template>
    <div class="custom-control custom-radio">
        <div>
            <input
                :id="`radio-${config?.id}`"
                v-model="modelRadio"
                :type="config?.type ?? 'radio'"
                :class="{
                    [config.classCustom]: config.classCustom,
                    'custom-control-input': !config?.classCustom,
                    disabled_cursor: disabled
                }"
                :name="config?.name ?? ''"
                :value="config?.value ?? 'on'"
                :disabled="disabled"
                @change="handleChangeRadio"
            />
            <label :for="`radio-${config.id}`" class="custom-control-label">{{ config.label }}</label>
        </div>
        <div v-if="config.error" class="invalid-feedback d-block">
            <span v-if="config.isRequired">{{
                $t('validateField.required', {
                    field: config.errorField
                })
            }}</span>
        </div>
    </div>
</template>

<script>
export default {
    name: 'InputRadio',
    props: {
        model: {
            required: true
        },
        disabled: {
            type: Boolean,
            required: false,
            default: false
        },
        config: {
            type: Object,
            required: true,
            default() {
                return {
                    id: 'input-radio',
                    name: '',
                    value: '',
                    label: '',
                    classCustom: 'custom-control-input',
                    type: 'radio',
                    isRequired: false,
                    error: false,
                    errorField: ''
                };
            }
        }
    },
    data() {
        return {
            modelRadio: ''
        };
    },
    watch: {
        '$props.model': {
            handler: function (newValue) {
                this.modelRadio = newValue;
            },
            immediate: true,
            deep: true
        },

        '$props.config': {
            handler: function (newConfig) {
                this.config = newConfig;
                this.$emit('update:config', this.config);
            },
            immediate: true,
            deep: true
        },

        modelRadio: {
            handler: function (newModelRadio) {
                let config = this.config;
                config.error = false;
                this.$emit('update:model', newModelRadio);
                this.$emit('update:config', config);
            }
        }
    },
    methods: {
        handleChangeRadio() {
            let config = this.config;
            config.error = false;
            this.$emit('update:model', this.modelRadio);
            this.$emit('change', this.modelRadio);
        }
    }
};
</script>

<style lang="scss" scoped></style>
