<template>
    <div class="custom-control custom-checkbox">
        <div>
            <input
                :id="`check-${config?.id}`"
                v-model="modelCheck"
                :type="config?.type ?? 'checkbox'"
                :class="{
                    [config.classCustom]: config.classCustom,
                    'custom-control-input': !config?.classCustom,
                    disabled_cursor: disabled
                }"
                :name="config?.name ?? ''"
                :value="config?.value ?? 'on'"
                :disabled="disabled"
                @change="handleChangeCheckBox"
            />
            <label :for="`check-${config.id}`" class="custom-control-label">{{ config.label }}</label>
        </div>
        <div v-if="config.error" class="invalid-feedback d-block">
            <span v-if="config.isRequired">{{
                $t('validateField.required', {
                    field: config.errorField
                })
            }}</span>
        </div>
    </div>
</template>

<script>
export default {
    name: 'InputCheckBox',
    props: {
        model: {
            required: true
        },
        disabled: {
            type: Boolean,
            required: false,
            default: false
        },
        config: {
            type: Object,
            required: true,
            default() {
                return {
                    id: 'input-checkbox',
                    name: '',
                    value: '',
                    label: '',
                    classCustom: 'custom-control-input',
                    type: 'checkbox',
                    isRequired: false,
                    error: false,
                    errorField: ''
                };
            }
        }
    },
    data() {
        return {
            modelCheck: []
        };
    },
    watch: {
        '$props.model': {
            handler: function (newValue) {
                this.modelCheck = newValue;
            },
            immediate: true,
            deep: true
        },

        '$props.config': {
            handler: function (newConfig) {
                this.config = newConfig;
                this.$emit('update:config', this.config);
            },
            immediate: true,
            deep: true
        },

        modelCheck: {
            handler: function (newModelCheck) {
                let config = this.config;
                config.error = false;
                this.$emit('update:model', newModelCheck);
                this.$emit('update:config', config);
            }
        }
    },
    methods: {
        handleChangeCheckBox() {
            let config = this.config;
            config.error = false;
            this.$emit('update:model', this.modelCheck);
            this.$emit('change', this.modelCheck);
        }
    }
};
</script>

<style lang="scss" scoped></style>
